<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>            
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="
                  '/plataforma/' +
                    $route.params.id_plataforma +
                    '/secretaria'
                "
              >Secretaria</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Cadastro de Usuários</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Cadastro de
                  <span id="txtDashboardNomePlataforma">usuários</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">                
                <div
                  v-if="fastUsuarioRestricoes.professores"
                  class="col-12 shadow p-3 mb-4 bg-white rounded"
                >
                  <h4 class="aluno_font_color">
                    Lista de Professores ({{ fastUsuariosProfessoresFiltro.length }})
                  </h4>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Filtrar por nome ou email"
                        @keyup="filtraProfessores"
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1 text-right">
                      <button
                        class="btn btn-sm btn-primary"
                        @click.prevent="showModal('modalCadastrarProfessor')"
                      >
                        <small>Cadastrar professor</small>
                      </button>
                    </div>
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm table-striped">
                        <thead class="thead-dark">
                          <tr>
                            <th />
                            <th>
                              <small class="font-weight-bold">Nome</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Email</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ativo</small>
                            </th>                            
                            <th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="fastUsuariosProfessoresFiltro.length">
                          <tr
                            v-for="u in pageOfProfessores"
                            :key="u.id_usuario"
                          >
                            <td>
                              <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(u.image_prof) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                            </td>
                            <td>
                              <small v-if="u.id_usuario">{{ u.nome_usuario }}</small>
                              <small v-else>{{ u.first_name }} {{ u.last_name }}</small>
                            </td>
                            <td>
                              <small>{{ u.email ? u.email : u.email_prof }}</small>
                            </td>
                            <td class="text-center">
                              <span v-if="u.id_usuario">
                                <small
                                  v-if="u.ativo == 'S'"
                                  class="badge badge-success"
                                >sim</small>
                                <small
                                  v-else
                                  class="badge badge-danger"
                                >não</small>
                              </span>
                              <small
                                v-else
                                class="text-danger"
                              >Usuário sem conta</small>
                            </td>                            
                            <td class="text-center">
                              <a
                                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/professor/' + u.id_professor"
                                class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                              >
                                <small class="text-white">Editar dados</small>
                              </a> 

                              <button
                                v-if="u.ativo == 'N'"
                                class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                @click="exibeModalUsuarioReenviar(u)"
                              >
                                <small>Enviar convite</small>
                              </button>

                              <button
                                v-if="u.id_usuario"
                                class="btn btn-sm btn-danger pt-0 pb-0"
                                @click="exibeModalUsuarioExcluir(u)"
                              >
                                <small>Excluir</small>
                              </button>
                              <button
                                v-else
                                class="btn btn-sm btn-danger pt-0 pb-0"
                                @click="exibeModalProfessorExcluir(u)"
                              >
                                <small>Excluir</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr class="text-center">
                            <td colspan="5">
                              Nenhum professor encontrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="10"
                        :items="fastUsuariosProfessoresFiltro"
                        @changePage="pageOfProfessores = $event"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="fastUsuarioRestricoes.alunos"
                  class="col-12 shadow p-3 mb-4 bg-white rounded"
                >
                  <h4 class="aluno_font_color">
                    Lista de Alunos ({{ fastUsuariosAlunosFiltro.length }})
                  </h4>
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Filtrar por nome ou email"
                        @keyup="filtraAlunos"
                      >
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1 text-right">
                      <button
                        class="btn btn-sm btn-primary"
                        @click.prevent="showModal('modalCadastrarAluno')"
                      >
                        <small>Cadastrar aluno</small>
                      </button>
                    </div>
                    <div class="col-12 table-responsive mt-2">
                      <table class="table table-sm table-striped">
                        <thead class="thead-dark">
                          <tr>
                            <th />
                            <th>
                              <small class="font-weight-bold">Nome</small>
                            </th>
                            <th>
                              <small class="font-weight-bold">Email</small>
                            </th>
                            <th class="text-center">
                              <small class="font-weight-bold">Ativo</small>
                            </th>                            
                            <th class="text-center">
                              <small class="font-weight-bold">Ações</small>
                            </th>                           
                          </tr>
                        </thead>
                        <tbody v-if="fastUsuariosAlunosFiltro.length">
                          <tr
                            v-for="(u) in pageOfAlunos"
                            :key="u.id_usuario"
                          >   
                            <td>
                              <small>#{{ u.id_usuario }}</small>
                            </td>                         
                            <td>
                              <small>{{ u.nome_usuario == "null null" ? "" : u.nome_usuario }}</small>
                            </td>
                            <td>
                              <small>{{ u.email }}</small>
                            </td>
                            <td class="text-center">
                              <small
                                v-if="u.ativo == 'S'"
                                class="badge badge-success mr-2"
                              >sim</small>
                              <small
                                v-else
                                class="badge badge-danger mr-2"
                              >não</small>
                            </td>                           
                            <td class="text-center">
                              <a
                                v-if="u.id_pessoa"
                                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + u.id_pessoa"
                                class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                              >
                                <small class="text-white">Editar dados</small>
                              </a>   
                              <button
                                v-else
                                class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                @click="criaUsuarioPessoa(u)"
                              >
                                <small>Criar pessoa</small>
                              </button>

                              <button
                                v-if="u.ativo == 'N'"
                                class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                @click="exibeModalUsuarioReenviar(u)"
                              >
                                <small>Enviar convite</small>
                              </button>

                              <button
                                class="btn btn-sm btn-danger pt-0 pb-0"
                                @click="exibeModalUsuarioExcluir(u)"
                              >
                                <small>Excluir</small>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr class="text-center">
                            <td colspan="5">
                              Nenhum aluno encontrado
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="10"
                        :items="fastUsuariosAlunosFiltro"
                        @changePage="pageOfAlunos = $event"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCadastrarProfessor"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Cadastrar professor</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarProfessor')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Nome</label>
              <input
                v-model="fastProfessorNovo.Usuario.First_name"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
              <label for>Sobrenome</label>
              <input
                v-model="fastProfessorNovo.Usuario.Last_name"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <label for>Email</label>
              <input
                v-model="fastProfessorNovo.Usuario.Email"
                class="form-control"
                type="text"
                placeholder
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
              <label for>Formação</label>
              <select
                v-model="fastProfessorNovo.licenciatura"
                class="form-control"
              >
                <option value="0">
                  Nenhuma
                </option>
                <option value="1">
                  Graduação
                </option>
                <option value="2">
                  Especialização
                </option>
                <option value="3">
                  Mestrado
                </option>
                <option value="4">
                  Doutorado
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <label for>Imagem do professor</label>
              <vue-cropper @image="fastProfessorNovo.image = $event" />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="cadastrarProfessor()"
                >
                  <small>Cadastrar</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalUsuarioExcluir"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioExcluir')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioExcluir()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalProfessorExcluir"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir professor?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalProfessorExcluir')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaProfessorExcluir()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCadastrarAluno"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Cadastrar aluno</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCadastrarAluno')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nome</label>
              <div class="form-group">
                <input
                  v-model="fastAlunoNovo.Usuario.First_name"
                  class="form-control"
                  type="text"
                  maxlength="50"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Sobrenome</label>
              <div class="form-group">
                <input
                  v-model="fastAlunoNovo.Usuario.Last_name"
                  class="form-control"
                  type="text"
                  maxlength="50"
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Email</label>
              <div class="form-group">
                <input
                  v-model="fastAlunoNovo.Usuario.Email"
                  class="form-control"
                  type="email"
                >
              </div>
            </div> 
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click.prevent="cadastraAluno()"
                >
                  Cadastrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioReenviar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Enviar convite?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioReenviar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  v-if="!fastCarregandoAcao"
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="alteraUsuario('R')"
                >
                  Confirmar
                </button>
                <div
                  v-if="fastLinkConvite"
                  class="text-center"
                >
                  <h6>Link do convite</h6>
                  <div class="input-group">
                    <input
                      id="fastLinkConvite"
                      v-model="fastLinkConvite"
                      type="text"
                      class="form-control"
                      placeholder="text"
                      readonly
                    >
                    <div class="input-group-btn">
                      <button
                        class="btn btn-default rounded-0"
                        @click="copyTestingCode()"
                      >
                        <i class="far fa-copy" />
                      </button>
                    </div>
                  </div>
                  <h6
                    class="text-info mt-4"
                    v-text="fastLinkConviteMsg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import VueCropper from "../components/ImageCropperUsuario";
export default {
  name: "HomeInternoSecretariaUsuarios",
  components: {
    Pagination,
    VueCropper,
  },
  mixins: [methods],
  data: function() {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fastUsuarioLogado: {
        id_usuario: this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario,
      },      
      // Professores
      fastUsuariosProfessores: [],
      fastUsuariosProfessoresFiltro: [],
      pageOfProfessores: [],
      fastProfessorNovo: {
        id_plataforma: 0,
        id_usuario: "",
        id_professor_concurseiro: 0,
        id_professor: 0,        
        licenciatura: "0",
        image: "https://toussaint.concurseiro.com/css/images/user.png",
        Usuario: {
          First_name: "",
          Last_name: "",
          Email: ""
        }
      },
      // Alunos
      fastUsuariosAlunos: [],
      fastUsuariosAlunosFiltro: [],
      pageOfAlunos: [],
      fastAlunoNovo: {
        id_plataforma: 0,
        Usuario: {
          First_name: "",
          Last_name: "",
          Email: ""
        }
      },
      // Status
      statusConvite: "",
      // Exclusão
      fastUsuarioExcluir: [],
      fastProfessorSemContaExcluir: [],
      fastUsuarioEditar: {},     
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Ações
      fastAcao: false,
      fastLinkConvite: "",
      fastLinkConviteMsg: "",
      // Restrições
      fastUsuarioRestricoes: {
        administradores: false,
        professores: false,
        alunos: false,
      },
      // Loading da ação
      fastCarregandoAcao: false,
    };
  },
  mounted: function() {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Página desativada", "error");
/*
          if (this.$store.state.fastPermissoes.ativo == "S" && this.$store.state.fastPermissoes.administrador == "S") {
             if (this.getUrlParameter("Professores") && this.$store.state.fastPermissoes.edita_professores == "S") {
              this.fastUsuarioRestricoes.professores = true;
              this.getFastUsuariosProfessores();
            } else if (this.getUrlParameter("Alunos") && this.$store.state.fastPermissoes.edita_alunos == "S") {
              this.fastUsuarioRestricoes.alunos = true;
              this.getFastUsuariosAlunos();
            } else {
              if (this.$store.state.fastPermissoes.edita_professores == "S") {
                this.fastUsuarioRestricoes.professores = true;
                this.getFastUsuariosProfessores();
              }
              if (this.$store.state.fastPermissoes.edita_alunos == "S") {
                this.fastUsuarioRestricoes.alunos = true;
                this.getFastUsuariosAlunos();
              }
            }
            this.$store.state.fastCarregando = false;            
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Você não possui permissões", "error");
          }*/
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    filtraProfessores(e) {
      let text = e.target.value;
      this.fastUsuariosProfessoresFiltro = this.fastUsuariosProfessores.filter((e) => {
        if (e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.email_prof.toLowerCase().indexOf(text.toLowerCase()) != "-1") return e;
      });
    },
    filtraAlunos(e) {
      let text = e.target.value;
      this.fastUsuariosAlunosFiltro = this.fastUsuariosAlunos.filter((e) => {
        return e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.email.toLowerCase().indexOf(text.toLowerCase()) != "-1";
      });
    },
    async getFastUsuariosProfessores() {
      this.statusConvite = "";
      this.fastUsuariosProfessores = [];
      this.fastUsuariosProfessoresFiltro = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_professor/lista_semcom_usuario?id_plataforma=" + this.$route.params.id_plataforma, this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        //console.log("Professores", json);
        if (obj.length > 0) {
          this.fastUsuariosProfessores = obj;
          this.fastUsuariosProfessoresFiltro = obj;
        }
        //this.getFastUsuariosProfessoresSemConta();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getFastUsuariosAlunos() {
      this.statusConvite = "";
      this.fastUsuariosAlunos = [];
      this.fastUsuariosAlunosFiltro = [];
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/lista_usuarios_plataforma?id_plataforma=" + this.$route.params.id_plataforma + "&administrador=N&professor=N", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        //console.log("getFastUsuariosAlunos", json);
        let obj = Array.from(json);
        if (obj.length > 0) {
          obj.forEach((u) => {
            if (!u.nome_usuario) u.nome_usuario = "Não cadastrado";
          });
          this.fastUsuariosAlunos = obj;
          this.fastUsuariosAlunosFiltro = obj;
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalUsuarioExcluir(usuario) {
      this.fastUsuarioExcluir = usuario;
      this.showModal("modalUsuarioExcluir");
    },
    async confirmaUsuarioExcluir() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/exclui_usuario", this.fastAjaxOptions("POST", JSON.stringify(this.fastUsuarioExcluir)));
        let json = await resp.json();
        let obj = Array.from(json);

        if (this.fastUsuarioExcluir.administrador == "S") this.getFastUsuariosAdministradores();
        else if (this.fastUsuarioExcluir.professor == "S") this.getFastUsuariosProfessores();
        else this.getFastUsuariosAlunos();

        this.fastUsuarioExcluir = [];
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Usuário excluído com sucesso",
        });
        this.hideModal("modalUsuarioExcluir");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalProfessorExcluir(usuario) {
      this.fastProfessorSemContaExcluir = usuario;
      this.showModal("modalProfessorExcluir");
    },
    async confirmaProfessorExcluir() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_professor/exclui", this.fastAjaxOptions("POST", JSON.stringify(this.fastProfessorSemContaExcluir)));
        let json = await resp.json();
        let obj = Array.from(json);

        this.getFastUsuariosProfessores();

        this.fastProfessorSemContaExcluir = [];
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Professor excluído com sucesso",
        });
        this.hideModal("modalProfessorExcluir");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    // Aluno
    async cadastraAluno() {      
      this.fastAlunoNovo.id_plataforma = this.$route.params.id_plataforma;
      let erros = [];
      if (!this.fastAlunoNovo.Usuario.First_name) erros.push("O nome é obrigatório") 
      if (!this.fastAlunoNovo.Usuario.Last_name) erros.push("O sobrenome é obrigatório") 
      if (!this.fastAlunoNovo.Usuario.Email) erros.push("O email é obrigatório") 
      if (!erros.length) {
        this.$store.state.fastCarregando = true;
        this.promiseInserirFastApi(this.fastAlunoNovo, "fast_plataforma_usuario/secretaria/aluno").then((res) => {
          console.log("cadastraAluno", JSON.parse(res))
          if (JSON.parse(res).id_pessoa) {
              this.exibeToasty("Aluno cadastrado com sucesso", "success");
              window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/aluno/' + JSON.parse(res).id_pessoa;
          } else {            
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Erro ao cadastrar aluno", "error");
          }    
        }).catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao cadastrar aluno", "error");
        });
      } else {
        this.$store.state.fastCarregando = false;
        erros.forEach(e => this.exibeToasty(e, "error"))
      }      
    },
    async criaUsuarioPessoa(usuario) {
      this.fastAlunoNovo.id_plataforma = this.$route.params.id_plataforma;
      let erros = [];
      if (usuario.nome_usuario.split(" ").length < 2) erros.push("Este usuário não possui sobrenome") 
      else {
        this.fastAlunoNovo.Usuario.First_name = usuario.nome_usuario.split(" ")[0];
        this.fastAlunoNovo.Usuario.Last_name = usuario.nome_usuario.split(" ").shift();
      }     
      this.fastAlunoNovo.Usuario.Email = usuario.email;      
      if (!this.fastAlunoNovo.Usuario.First_name) erros.push("O usuário não possui nome") 
      if (!this.fastAlunoNovo.Usuario.Last_name) erros.push("O usuário não possuir sobrenome") 
      if (!this.fastAlunoNovo.Usuario.Email) erros.push("Erro ao resgatar email do usuário") 
      if (!erros.length) {
        this.$store.state.fastCarregando = true;
        this.promiseInserirFastApi(this.fastAlunoNovo, "fast_plataforma_usuario/secretaria/aluno").then((res) => {
          console.log("cadastraAluno", JSON.parse(res))
          if (JSON.parse(res).id_pessoa) {
              this.exibeToasty("Aluno cadastrado com sucesso", "success");
              window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/aluno/' + JSON.parse(res).id_pessoa;
          } else {            
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Erro ao cadastrar aluno", "error");
          }    
        }).catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao cadastrar aluno", "error");
        });
      } else {
        this.$store.state.fastCarregando = false;
        erros.forEach(e => this.exibeToasty(e, "error"))
      }      
    },
    async cadastrarProfessor() {
      this.fastProfessorNovo.id_plataforma = this.$route.params.id_plataforma;
      let erros = [];
      if (!this.fastProfessorNovo.Usuario.First_name) erros.push("O nome é obrigatório") 
      if (!this.fastProfessorNovo.Usuario.Last_name) erros.push("O sobrenome é obrigatório") 
      if (!this.fastProfessorNovo.Usuario.Email) erros.push("O email é obrigatório") 
      if (!this.fastProfessorNovo.image) this.fastProfessorNovo.image = "https://toussaint.concurseiro.com/css/images/user.png";

      if (!erros.length) {
        this.$store.state.fastCarregando = true;
        this.promiseInserirFastApi(this.fastProfessorNovo, "fast_plataforma_usuario/secretaria/professor").then((res) => {
          console.log("cadastrarProfessor", JSON.parse(res))
          if (JSON.parse(res).id_professor) {
              this.exibeToasty("Professor cadastrado com sucesso", "success");
              window.location.href = '/plataforma/' + this.$route.params.id_plataforma + '/secretaria/professor/' + JSON.parse(res).id_professor;
          } else {            
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Erro ao cadastrar professor", "error");
          }    
        }).catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao cadastrar professor", "error");
        });
      } else {
        this.$store.state.fastCarregando = false;
        erros.forEach(e => this.exibeToasty(e, "error"))
      }   
    },
    exibeModalUsuarioReenviar(usuario) {
      this.fastUsuarioEditar = usuario;
      this.fastCarregandoAcao = false;
      this.fastLinkConvite = "";
      this.fastLinkConviteMsg = "";
      this.showModal("modalUsuarioReenviar");
    },
    async alteraUsuario(acao) {
      this.fastLinkConvite = "";
      let mensagem_sucesso = "";
      let fast_plataforma_usuario = {};
      if (acao == "R") {
        fast_plataforma_usuario = {
          email: this.fastUsuarioEditar.email,
          administrador: this.fastUsuarioEditar.administrador,
          professor: this.fastUsuarioEditar.professor,
          id_usuario: this.fastUsuarioEditar.id_usuario,
          id_plataforma: this.$route.params.id_plataforma,
          convite_enviado: "S",
          data_convite_reenvio: new Date()
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
          aceito: "N",
          data_aceite: null,
          ativo: "N",
          token: '1',
        };
        mensagem_sucesso = "Convite reenviado com sucesso";
      } 
      //console.log(this.fastUsuarioEditar);

      this.fastCarregandoAcao = true;
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        console.log(json);
        if (acao == "R") {
          this.fastLinkConvite = settings.endFastEad + "convite?token=" + json[0].token;
        }
        this.exibeToasty(mensagem_sucesso, "success");
        this.$store.state.fastCarregando = false;

        this.fastUsuarioEditar = {};
      } catch (e) {
        this.fastCarregandoAcao = false;
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#fastLinkConvite");
      testingCodeToCopy.select();
      testingCodeToCopy.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.fastLinkConviteMsg = "Link copiado com sucesso!";
    },
  },
};
</script>

<style></style>
